<template>
  <div class="">
    <div class="">
      <b-card
        v-if="false"
        ref="leftSection"
        class="left d-none d-xl-block mr-1"
        style="width: 250px;"
      >
        <p style="color: red;">
          왼쪽 섹션 (너비: {{ leftWidth }}px)
        </p>
      </b-card>

      <!-- Center-Contents -->
      <b-card
        ref="centerSection"
        class="center"
      >
        <div>
          <p style="color: red;">
            가운데 섹션 (너비: {{ centerWidth }}px)
          </p>
        </div>
        <!-- Games-->
        <div>
          <div
            v-for="(item, i) in filteredItems"
            :key="i"
            class="d-flex flex-md-row"
          >
            <div
              style="width: 100%;"
            >
              <div class="scoreboard">
                <div class="top-row">
                  <div class="period">
                    상태: 1st Half
                  </div>
                  <div class="extra-options">
                    +13 추가 배팅옵션
                  </div>
                </div>
                <div class="contents-row">
                  <div v-if="false">
                    <div class="header">
                      <span class="time">2024-04-28 19:15</span>
                    </div>
                    <div class="score">
                      <div class="team-score">
                        <span class="score-number">1</span>
                      </div>
                      <span class="vs">VS</span>
                      <div class="team-score">
                        <span class="score-number">0</span>
                      </div>
                    </div>
                  </div>

                  <div class="score-point">
                    <div>0</div>
                    <div>
                      <span class="time">2024-04-28 19:15</span>
                      <span class="vs">VS</span>
                    </div>
                    <div>1</div>
                  </div>

                  <div class="odds ">
                    <b-button
                      variant="outline-secondary"
                      class="d-flex align-items-center justify-content-between"
                    >
                      <span class="text-left">PEC 조폐공</span>
                      <span class="text-right">1.40</span>
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      class="text-center"
                    >
                      <span class="odd">4.75</span>
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      class="d-flex align-items-center justify-content-between"
                    >
                      <span class="text-left">헤라클레스 알멜로</span>
                      <span class="text-right">6.50</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="false"
              class="main"
            >
              <div>
                <div class="league-logo d-flex align-items-center">
                  <img
                    class=""
                    style="width: 24px; margin-right: 5px;"
                    :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/${getLeagueImage(item.leagueName)}.svg`"
                    @error="handleImageError"
                  >
                  <span>
                    {{ item.leagueName }}
                  </span>
                </div>
                <!-- <div> {{ i }} <br> {{ item }} </div> -->
              </div>

              <div class="">
                <div>
                  <feather-icon
                    icon="ClockIcon"
                  />
                  {{ item.startDate }} {{ item.startTime }}
                </div>
              </div>
              <div>
                <span>
                  <span>{{ item.score.split(':')[0] }}</span>
                  <span> vs </span>
                  <span>{{ item.score.split(':')[1] }}</span>
                </span>
              </div>
              <div>
                {{ item.period }}
              </div>
            </div>
            <div class="desc">
              <div> {{ i }} <br> {{ item }} </div>
            </div>
          </div>
        </div>
      </b-card>
      <!-- v-show를 사용하여 너비에 따라 오른쪽 섹션을 조건부로 표시 -->
      <b-card
        v-show="width > 1493"
        ref="rightSection"
        class="right ml-1"
        style="width: 300px;"
      >
        <p style="color: red;">
          오른쪽 섹션 (너비: {{ rightWidth }}px)
        </p>
      </b-card>
    </div>
    <p>
      componentName: {{ componentName }} <br>
      currentURL: {{ currentURL }} <br>
    </p>
    <hr>
    <p>
      filteredItems: {{ filteredItems.length }}
    </p>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Grid',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,

      width: window.innerWidth,
      leftWidth: 0,
      centerWidth: 0,
      rightWidth: 0,

      searchText: '',

      selectedLeague: 'all', // 선택된 리그를 저장할 데이터
      selectedSports: '', // 선택된 스포츠 항목

      orgData: [],
      gameRows: [],
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.orgData[0] || []

      // 선택된 리그에 해당하는 경기만 필터링합니다.
      if (this.selectedLeague !== 'all') {
        filteredItems = filteredItems.filter(gameRow => gameRow.leagueName === this.selectedLeague)
      }

      // 검색어로 필터링합니다.
      const searchText = this.searchText.toLowerCase().trim()
      if (searchText) {
        const lowerCasedSearchText = searchText.toLowerCase()
        filteredItems = filteredItems.filter(gameRow => gameRow.leagueName.toLowerCase().includes(lowerCasedSearchText)
          || gameRow.homeName.toLowerCase().includes(lowerCasedSearchText)
          || gameRow.awayName.toLowerCase().includes(lowerCasedSearchText))
      }

      return filteredItems
    },
  },
  watch: {},
  created() {
    console.log(this.componentName, 'created()')
  },
  mounted() {
    console.log(this.componentName, 'mounted()')
    window.addEventListener('resize', this.handleResize) // 이벤트 리스너 등록
    this.$nextTick(() => {
      this.handleResize() // DOM 업데이트 이후 너비 업데이트
    })
    this.fetchData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize) // 컴포넌트 제거 시 이벤트 리스너 제거
  },
  methods: {
    fetchData() {
      console.log(this.componentName, 'fetchData()')

      this.$set(this.orgData, 0, '')
    },
    handleResize() {
      this.width = window.innerWidth
      if (this.$refs.leftSection) {
        this.leftWidth = this.$refs.leftSection.offsetWidth
      }
      if (this.$refs.centerSection) {
        this.centerWidth = this.$refs.centerSection.offsetWidth
      }
      if (this.$refs.rightSection && this.width > 1493) {
        this.rightWidth = this.$refs.rightSection.offsetWidth
      } else {
        this.rightWidth = 0
      }
    },
    getLeagueImage(leagueName) {
      let imageName

      if (this.selectedSports === 'soccer') {
        imageName = leagueName.split(' ', 1)
      } else if (this.selectedSports === 'basketBall') {
        imageName = leagueName
      } else {
        imageName = leagueName
      }
      return imageName
    },
    handleImageError(e) {
      e.target.src = 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/default.svg'
    },
  },
}
</script>

<style>
.left {
  background-color: lightblue;
}

.center {
  background-color: lightgreen;
}

.right {
  background-color: lightcoral;
}

.scoreboard {
    position: relative;
    background-color: #222;
    border-radius: 10px;
    width: 100%; /* 변경된 부분: 고정 너비를 백분율로 설정 */
    padding: 20px 20px 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    display: flex; /* flexbox 레이아웃 적용 */
    flex-direction: row;
    justify-content: center
}

.top-row { /* 새로운 클래스 추가 */
    position: absolute;
    top: -10px; /* 높이가 증가함에 따라 위치를 조금 더 위로 조정 */
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.period, .extra-options {
    width: 40%;
    text-align: center; /* 텍스트를 중앙으로 정렬 */
    padding: 10px 5px; /* 패딩을 조정하여 높이에 기여 */
    color: #fff; /* 글자 색상 */
    background-color: #444; /* 배경 색상 */
    border-radius: 5px; /* 모서리 둥글게 처리 */
    white-space: nowrap; /* 개행 방지 */
    text-overflow: ellipsis; /* 내용이 넘칠 때 말줄임표로 표시 */
    font-size: 2vw; /* VS 텍스트 크기를 상대적으로 작게 설정 */
}

.period {
    margin-left: 1rem; /* 오른쪽 여백 조정 */
}

.extra-options {
    margin-right: 1rem; /* 왼쪽 여백 조정 */
}

.contents-row {
    display: flex;
    flex-direction: column;
    background-color: #222; /* 어두운 배경 색상 */
    margin-top: 15px;
    padding: 5px;
    border-radius: 5px;
    /* box-shadow: 0 4px 8px rgba(0,0,0,0.5); 상자 그림자 추가 */
    width: 100%; /* 컨테이너 너비 */
}

.header {
    display: flex;
    justify-content: center; /* 시간을 중앙에 위치시킴 */
    padding-bottom: 5px; /* 하단 패딩 추가 */
}

.score {
    display: flex;
    justify-content: space-evenly;
    align-items: center; /* 세로 중앙 정렬 */
    padding-bottom: 5px; /* 하단 패딩 추가 */
    white-space: nowrap; /* 개행 방지 */
    margin: 0 50px; /* 좌우 마진을 추가하여 간격 확장 */
}

.team-score {
    display: flex;
    align-items: center; /* 팀 점수를 세로 중앙 정렬 */
    margin: 0 20px; /* 양쪽에 여백을 추가하여 시각적으로 구분 */
    font-size: 28px; /* 글자 크기를 크게 설정 */
    font-weight: bold; /* 볼드체로 설정 */
}

.vs {
  font-size: 20px; /* VS 텍스트 크기를 상대적으로 작게 설정 */
  font-weight: normal; /* 표준 글씨 두께 */
}

.odds {
  display: grid;
  grid-template-columns: 40% 20% 40%; /* 가로 배열 간격을 45:10:45로 설정 */
}

.odds .btn{
  padding: 0.6rem 0.786rem;
  border-radius: 0.2rem;
  font-size: 1em;
  white-space: nowrap;
}

.team-name1 {
  font-weight: bold;
}

.odd1 {
  font-weight: bold;
}

/* */
  .score-point {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 5vw; /* 좌우 마진을 viewport width의 5%로 설정하여 간격 조정 */
  }

  .score-point > div {
    font-size: 5vw; /* 글자 크기를 viewport width의 5%로 설정 */
    font-weight: bold; /* 볼드체로 설정 */
    text-align: center;
  }

  .score-point .time {
    font-size: 1.8vw; /* VS 텍스트 크기를 상대적으로 작게 설정 */
    font-weight: normal; /* 표준 글씨 두께 */
    display: block;
    text-align: center;
    display: block;
    text-align: center;
  }

  .score-point .vs {
    font-size: 2.5vw; /* VS 텍스트 크기를 상대적으로 작게 설정 */
    display: block;
    text-align: center;
  }

@media (max-width: 805px) {
    .flex-md-row {
      flex-wrap: wrap; /* 필요시 줄바꿈 */
    }
  }
</style>
